<template>
  <div
    :style="'order:' + bookingOrder"
  >
    <div
      :style="setStyleBooking()"
      class="booking-item position-relative"
    >
      <closed-playground
        v-if="booking.status === 'closed'"
      />
      <confirmed-booking
        v-else
        :key="'booking-'+ bookingKey"
        :size="size"
        :params="params"
        :booking="booking"
        :display="display"
        :duration="duration"
        :orientation="orientation"
        @on:booking-click="showBookingDetail"
        @on:booking-delete-modal="deleteBookingCard"
      />
      <popup-over
        :popover-id="booking.id"
        :booking="booking"
        :params="params"
        :playground-position="playground.position"
      />
      <booking-details
        v-if="model"
        :key="'booking-details-'+ bookingKey"
        :display="display"
        :booking-id="booking.id"
        position="left"
        @hidden="onHide"
        @hidden-and-update="display = false;$emit('on:booking-reload')"
      />
      <booking-delete-modal
        v-if="booking"
        :booking="booking"
        :display-delete-message="displayDeleteMessage"
        :hide-modal-message="hideModalMessage"
        @on:confirm-delete="onConfirm"
        @on:cancel-delete="onCancel"
      />
    </div>
  </div>
</template>
<script>
import {SUCCESS} from "@plugins/flash";
import {disableBodyScrollY, enableBodyScrollY} from "@/utils/style";
import {deleteBooking} from "@api/doinsport/services/bookings/booking.api";

export default {
  data: () => ({
    display: false,
    model: null,
    book: null,
    duration: 0,
    bookingKey: 0,
    dataModal: null,
    isBusy: false,
    displayDeleteMessage: false,
    hideModalMessage: false,
  }),
  components: {
    PopupOver: () => import('./Tooltip'),
    PendingBooking: () => import('./cards/Pending'),
    BookingDetails: () => import('./details/Index'),
    ClosedPlayground: () => import('./cards/Closed'),
    ConfirmedBooking: () => import('./cards/Confirmed'),
    BookingDeleteModal: () => import('./BookingDeleteModal'),
  },
  props: {
    playgroundPosition: {
      type: Number,
      default: () => 0,
    },
    booking: {
      type: Object,
      default: () => {
      },
    },
    params: {
      type: Object,
      default: () => {
      },
    },
    playground: {
      type: Object,
      default: () => {
      },
    },
    display: {
      type: Boolean,
      default: false
    },
    size: {
      type: Number,
      default: () => 1,
    },
    orientation: {
      type: String,
      default: () => 'vertical',
    }
  },
  created() {
    this.duration = this.$moment.utc(this.booking.endAt).diff(this.$moment.utc(this.booking.startAt), 'minutes');
  },
  computed: {
    bookingOrder() {
      const timezone = this.$store.getters["auth/currentClub"].timezone;

      const slotDate =  this.$moment.utc(this.$store.getters["planning/getDate"]).tz(timezone);

      const hhFormat = this.$moment.utc(this.booking.startAt).tz(timezone).format('HH');
      const mmFormat = this.$moment.utc(this.booking.startAt).tz(timezone).format('mm');

      slotDate.set({hour: hhFormat, minute: mmFormat, second: 0, millisecond: 0});
      slotDate.toISOString();
      slotDate.format();

      return slotDate.unix();
    }
  },
  methods: {
    onCancel() {
      this.hideModalMessage = !this.hideModalMessage;
    },
    onConfirm() {
      if (false === this.isBusy) {
        this.isBusy = true;

        deleteBooking(this.booking.id, {canceled: true})
          .then(res => {
            if (res) {
              this.$flash(this.$t('delete_booking.title_toast'), this.$t('delete_booking.success_content'), 3000, SUCCESS);
              this.$emit('on:booking-cancel', res.data);
            }
          })
          .finally(() => {
            this.onCancel();
            this.isBusy = false;
          })
        ;
      }
    },
    deleteBookingCard() {
      this.displayDeleteMessage = !this.displayDeleteMessage;
    },
    onHide() {
      enableBodyScrollY();
      this.display = false;
      this.model = null;
      this.$emit('on:booking-reload');
    },
    setStyleBooking() {
      if (this.orientation === 'vertical') {
        return {
          'height': `${(this.duration * this.size)}px`
        }
      } else {
        return {
          'width': `${(this.duration * 2 * this.size)}px`,
          'height': '100%'
        }
      }
    },
    showBookingDetail() {
      this.bookingKey++;
      this.model = JSON.parse(JSON.stringify(this.booking));

      this.$nextTick(() => {
        setTimeout(() => {
          disableBodyScrollY();
          this.display = true;
        }, 50)
      });
    },
    closeModalReload() {
      this.$emit('on:booking-reload');
    }
  },
  beforeDestroy() {
    enableBodyScrollY();
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/bookings/_bookings.scss";
</style>
